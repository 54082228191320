import React from 'react';
import { Link } from 'gatsby';
import {
  Button,
  NavbarBrand,
  Navbar,
  Nav,
  NavItem,
  Container,
} from 'react-bootstrap';
import { getSmallLogo } from 'components/images/small_logo';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

type NavButtonProps = {
  path?: string;
  href?: string;
  title?: string;
  closeSideNav(): void;
  shouldOpenNewTab?: boolean;
  children?: React.ReactNode;
};
const NavButton = ({
  path,
  children,
  closeSideNav,
  href,
  title,
  shouldOpenNewTab,
}: NavButtonProps) => {
  if (href) {
    return (
      <NavItem onClick={closeSideNav} as="li">
        <a
          href={href}
          title={title}
          target={shouldOpenNewTab ? '_blank' : undefined}
          rel="noreferrer"
        >
          <Button title={title} className="btn-neutral" variant="link">
            {children}
          </Button>
        </a>
      </NavItem>
    );
  }
  return (
    <NavItem onClick={closeSideNav} as="li">
      <Link to={path}>
        <Button className="btn-neutral" variant="link">
          {children}
        </Button>
      </Link>
    </NavItem>
  );
};

export const NavHeader = () => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const openSideNav = () => {
    document.documentElement.classList.toggle('nav-open');
    setCollapseOpen(true);
  };
  const closeSideNav = () => {
    document.documentElement.classList.toggle('nav-open');
    setCollapseOpen(false);
  };

  return (
    <>
      {collapseOpen && <div id="bodyClick" onClick={closeSideNav} />}
      <Navbar
        variant="dark"
        expand="lg"
        collapseOnSelect
        expanded={collapseOpen}
        fixed="top"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" as={Link}>
              <GatsbyImage image={getSmallLogo()} alt="Sunset Skates Hawaii" />
            </NavbarBrand>

            <Navbar.Toggle
              id="navigation"
              as="button"
              aria-label="Toggle Navigation Bar"
              className="border-0"
              onClick={openSideNav}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse>
            <Nav className="ms-auto" navbar as="ul">
              <NavButton closeSideNav={closeSideNav} path="/">
                Home
              </NavButton>
              <NavButton closeSideNav={closeSideNav} path="/lessons">
                Lessons
              </NavButton>
              <NavButton
                closeSideNav={closeSideNav}
                href="https://app.acuityscheduling.com/schedule.php?owner=18777866"
                title="Schedule a Lesson"
                shouldOpenNewTab
              >
                Book
              </NavButton>
              <NavButton
                closeSideNav={closeSideNav}
                href="https://sunset-skates-hawaii.myshopify.com"
                title="Visit the Sunset Skates Store"
                shouldOpenNewTab
              >
                Store
              </NavButton>
              <NavButton
                closeSideNav={closeSideNav}
                href="https://www.facebook.com/sunsetskates/"
                title="Facebook"
              >
                <FaFacebook />
              </NavButton>
              <NavButton
                closeSideNav={closeSideNav}
                href="https://www.instagram.com/Sunsetskates/"
                title="Instagram"
              >
                <FaInstagram />
              </NavButton>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
