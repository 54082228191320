import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'gatsby';

export const Footer = () => {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/lessons">Lessons</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/store">Store</Link>
              </li>
            </ul>
          </nav>
          <div className="credits ms-auto">
            <span className="copyright">
              {`© ${new Date().getFullYear()} Sunset Skates Hawaii. All rights reserved.`}
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
};
