import { useStaticQuery, graphql } from 'gatsby';

export const getSmallLogo = () => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logos/sunset_skates_small_black.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 90
            height: 60
            width: 217
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  return data.logo.childImageSharp.gatsbyImageData;
};
