import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type SEOProps = {
  pageTitle: string;
  isHomePage?: boolean;
};

export const SEO = ({ pageTitle, isHomePage }: SEOProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = site.siteMetadata.description;
  const title = site.siteMetadata.title;

  return (
    // @ts-expect-error  React Helmet Typings are Broken https://github.com/nfl/react-helmet/issues/646
    <Helmet
      htmlAttributes={{
        lang: 'en-us',
      }}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      title={pageTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      {isHomePage && (
        <script id="mcjs">
          {`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/39a3de3d46ba536d43838279a/c1a0b687f07331beaab4c9fbb.js");`}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
